<template>
    <div class="flex flex-col justify-center items-center min-h-screen h-full bg-fixed bg-profile bg-cover bg-center">
        <navbar />
        <div class=" w-3/4 h-1/2 bg-white bg-opacity-80 rounded-md p-4 text-black font-semibold">
            <h1 class="text-lg text-center">Welcome {{profil}}</h1>
            <p class="text-center mt-4">Christmas Presents v2.0</p>
            <p class="mt-4">Application de gestion de listes de Noël.</p>
            <p class="mt-4">Tu peux créer ta propre liste et consulter celle de ta famille dans l'onglet "Listes".</p>
            <p class="mt-4">Retrouve les cadeaux que tu as pris pour ta famille dans l'onglet "Cadeaux".</p>
            <p class="mt-4">Edite ton nom (ou pseudo) en cliquant sur ton avatar.</p>
        </div>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import { supabase } from "../supabase"
import Navbar from '../components/Navbar.vue';

export default {
  components: {  Navbar },
    name: 'Home',
    beforeMount() {
        this.checkProfil();
    },
    computed:{
        ...mapState(['user', 'profil'])
    },
    methods: {
        getProfil() {
            try {
                this.checkAuth();
            } catch (error) {
                console.error(error);
            }
        },
        checkProfil() {
            supabase.from("profiles").select().eq("id", this.user.id).single()
            .then(data => {
                if (data.error) {
                    this.createProfile(this.user.id);
                    return
                }
                return this.updateProfil(data.body.username);
            })
            .catch(error => {
                console.error(error);
            })
        },
        ...mapActions(['updateProfil', 'initProfil', 'createProfile']),
    }
}
</script>